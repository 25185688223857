.nav-container {
    background: rgba(255, 255, 255, 0.9);
    position: fixed;
    width: 100%;
    z-index: 3;
    color: black !important;
    backdrop-filter: blur(10px);
    font-weight: 400;
    font-size: 20px;
    top: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.nav-left {
    display: flex;
    align-items: center;
}

.hexagon {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    overflow: hidden;
}

.hexagon img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.nav-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
}

.navbar-nav {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-left: 20px;
}

.nav-link {
    color: #d80952;;
    text-decoration: none;
}

.nav-link2 {
    font-size: 30px;
    color: #d80952;
}

.customcenter {
    text-align: center;
}

.tiny {
    font-size: small;
}

.navbar-toggler {
    display: none;
}

.nav-link.active {
    border-bottom: 2px solid #d80952;
}

.nav-link:hover {
    color: #d80952;
    text-decoration: none;
}

@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
    }
    .navbar-collapse {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 0;
        overflow: hidden;
        width: 100%;
        transition: height 0.3s ease-in-out;
    }

  
    .navbar-nav {
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: 100vh;
    }

    .nav-item {
        margin-left: 0;
        margin-bottom: 10px;
        font-size: xx-large !important;
        margin-top: 7% !important;
    }
}

.customButton{
    background-color: transparent;
    border-color: antiquewhite;
    border-radius: 25%;
    font-size: x-large;
}