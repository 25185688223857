.headering2 {
  color: #212529;
  padding: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}



/* Set the width of the columns */
.two-column img {
  flex: 1 1 25%;
  max-width: 25%;
  /* make the image smaller */
}

.two-column p {
  flex: 1 1 75%;
  padding: 0 30px;
  /* padding-top: 25px; */
}

.small {
  font-size: small;
}

.skills-container {
  display:inline-flex;
  grid-template-columns: repeat(5, 1fr); /* Adjust number of columns */
  grid-gap: 5px; /* Adjust gap between tiles */
  justify-items: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
}

.tiling:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
.tiling:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
.tiling:nth-child(3) { grid-area: 1 / 3 / 2 / 4; }
.tiling:nth-child(4) { grid-area: 2 / 1 / 3 / 2; }
.tiling:nth-child(5) { grid-area: 2 / 2 / 3 / 3; }
.tiling:nth-child(6) { grid-area: 2 / 3 / 3 / 4; }
.tiling:nth-child(7) { grid-area: 3 / 1 / 4 / 2; }
.tiling:nth-child(8) { grid-area: 3 / 2 / 4 / 3; }
.tiling:nth-child(9) { grid-area: 3 / 3 / 4 / 4; }
.tiling:nth-child(10) { grid-area: 4 / 1 / 5 / 2; }

.tiling img {
  max-width: 100%;
  height: auto;
  display: block;
}

.tilingaward {
  flex: 0 0 120px;
  /* Adjust the width of each tile as needed */
  margin: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  /* glassmorphism */
  background: rgba(255, 255, 255, 0.318);
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
  text-align: center;
  padding: 5px;
}

.tiling img {
  border-radius: 20px;
  width: 60px;
  height: 60px;
}

.h_gallery_item {
  background: rgba(255, 255, 255, 0.258);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);

  padding: 10px;
}

.abouMeBox {
  /* glassmorphism */
  background: rgba(255, 255, 255, 0.318);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);


  padding: 10px;
}




@media screen and (max-width: 768px) {
  .col .headering2 {
    font-size: medium;
  }

  .two-column img {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .two-column p {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0 0px;
  }

  .tiling {
    flex: 1 1 25%;
    margin: 10px;
    display: flex;
    justify-content: center;

  }

  .me {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .skills-container {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on small screens */
    grid-template-rows: repeat(5, auto); /* Adjust the number of rows as needed */
    grid-gap: 10px; /* Increase gap for better readability on mobile */
  }

  .tiling:nth-child(1) { grid-area: auto; }
  .tiling:nth-child(2) { grid-area: auto; }
  .tiling:nth-child(3) { grid-area: auto; }
  .tiling:nth-child(4) { grid-area: auto; }.headering2 {
    color: #212529;
    padding: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .two-column img {
    flex: 1 1 25%;
    max-width: 25%;
  }
  
  .two-column p {
    flex: 1 1 75%;
    padding: 0 30px;
  }
  
  .small {
    font-size: small;
  }
  
  /* Main Skills Container */
  .skills-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Default 5 columns */
    grid-gap: 5px; /* Gap between tiles */
    justify-items: center;
    align-items: center;
  }
  
  .tiling {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.318);
    border-radius: 10px;
    box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
    text-align: center;
    padding: 5px;
  }
  
  .tiling img {
    border-radius: 20px;
    width: 60px;
    height: 60px;
  }
  
  .h_gallery_item {
    background: rgba(255, 255, 255, 0.258);
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
    padding: 10px;
  }
  
  .abouMeBox {
    background: rgba(255, 255, 255, 0.318);
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
    padding: 10px;
  }
  
  /* Responsive Design for Mobile (768px and below) */
  @media screen and (max-width: 768px) {
    .col .headering2 {
      font-size: medium;
    }
  
    .two-column img {
      flex: 1 1 100%;
      max-width: 100%;
    }
  
    .two-column p {
      flex: 1 1 100%;
      max-width: 100%;
      padding: 0;
    }
  
    .skills-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns for mobile */
      grid-template-rows: auto; /* Automatic rows */
      grid-gap: 10px; /* Increase gap for mobile */
    }
  
    .tiling {
      flex: 1 1 auto;
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .me {
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
  
    .container2 {
      flex-direction: column;
    }
  
    .image-column {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .text-column {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .pics {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  
  .container2 {
    display: flex;
    background: rgba(255, 255, 255, 0.318);
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
    padding: 10px;
    margin: 10px;
  }
  
  .image-column {
    flex-basis: 20%;
  }
  
  .text-column {
    flex-basis: 80%;
  }
  
  @media screen and (max-width: 768px) {
    .container2 {
      flex-direction: column;
    }
  
    .image-column {
      order: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  
    .text-column {
      order: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  
  .tiling:nth-child(5) { grid-area: auto; }
  .tiling:nth-child(6) { grid-area: auto; }
  .tiling:nth-child(7) { grid-area: auto; }
  .tiling:nth-child(8) { grid-area: auto; }
  .tiling:nth-child(9) { grid-area: auto; }
  .tiling:nth-child(10) { grid-area: auto; }


}


.pics {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}


.container2 {
  display: flex;
  background: rgba(255, 255, 255, 0.318);
  border-radius: 20px;
  box-shadow: 0 0 1px rgba(187, 183, 183, 0.2);
  padding: 10px;
  margin: 10px;
}

.image-column {
  flex-basis: 20%;
  /* Adjust the width of the image column */
}

.text-column {
  flex-basis: 80%;
  /* Adjust the width of the text column */
}


@media screen and (max-width: 768px) {
  .container2 {
    flex-direction: column;
  }


  .image-column {
    order: 2;
    /* center everything  */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
  }

  .text-column {
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}