.h_gallery_item {
    /* glass tiles */
    /* background: rgba(255, 255, 255, 0.318);
    border-radius: 20px;
    box-shadow: 0 0 1px rgba(187, 183, 183, 0.2); */

    background-color: rgba(255, 255, 255, 0.555);
    border-radius: 10px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    padding: 10px;
    margin: 5px;
    text-align: left;
  }


/* if the nav nav-tabs links are active, fill in the box */
.nav .nav-tabs .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  padding: 10px;
  margin: 10px;
}

.nav-link{
  color: #af0837;
}
@media screen and (max-width: 768px) {
  .gallery_f_inner {
    display: block;

}

  .h_gallery_item{
    margin-top: 20px;
    font-size: small;
    margin-top: none;
    margin-left: none;
    margin-right: none ;
    width: 100%;
    /* center the tile by making it take up the fill*/
    
  }
  .h_gallery_item h5{
    font-size: medium;
  }
  .h_gallery_item .mr-3{
    margin-bottom: 10px;
  }
}

h1 {
  overflow: hidden;
  text-align: center;
}

h1:before,
h1:after {
  background-color: #f12c8c;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

h1:before {
  right: 0.5em;
  margin-left: -50%;
}

h1:after {
  left: 0.5em;
  margin-right: -50%;
}

.headering{   
    transition: all 0.3s ease-in-out;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

@media screen and (max-width: 768px) {
  .headering{
    font-size: small;
    margin-top: none;
    margin-left: none;
    margin-right: none ;
    padding: 10px;
  }
}


.column .h_gallery_item{
  width: 450px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1080px) {
  .column .h_gallery_item{
    /* make 2 columns */
    width: 10px;
    margin-left: 20px;
    font-weight: 600;
  }
}

@media screen and (max-width: 768px) {
  .column .h_gallery_item{
    width: 289px;
    margin-left: 20px;
  }
}

/* no bullet points */


.centering{
  text-align: center;
}

img{
  margin-bottom:10px;
}

.navitem{
  font-size: x-large !important;
 
}

@media screen and (max-width: 768px) {
  .navitem{
    font-size: large !important;
    margin-top: 50px !important;
  }
}


